import React from "react"

import { Container, Row, Col } from 'reactstrap'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Link from "../components/link"

import artikler_fur_bg from '../assets/images/artikler_fur_bg.jpg'
import fur01 from '../assets/images/pdf/fur/01.pdf'
import fur02 from '../assets/images/pdf/fur/02.pdf'
import fur03 from '../assets/images/pdf/fur/03.pdf'
import fur04 from '../assets/images/pdf/fur/04.pdf'
import fur05 from '../assets/images/pdf/fur/05.pdf'
import fur06 from '../assets/images/pdf/fur/06.pdf'
import fur07 from '../assets/images/pdf/fur/07.pdf'
import fur08 from '../assets/images/pdf/fur/08.pdf'
import fur09 from '../assets/images/pdf/fur/09.pdf'
import fur10 from '../assets/images/pdf/fur/10.pdf'

import fur11 from '../assets/images/pdf/fur/11.pdf'
import fur12 from '../assets/images/pdf/fur/12.pdf'
import fur13 from '../assets/images/pdf/fur/13.pdf'
import fur14 from '../assets/images/pdf/fur/14.pdf'
import fur15 from '../assets/images/pdf/fur/15.pdf'
import fur16 from '../assets/images/pdf/fur/16.pdf'
import fur17 from '../assets/images/pdf/fur/17.pdf'
import fur18 from '../assets/images/pdf/fur/18.pdf'
import fur19 from '../assets/images/pdf/fur/19.pdf'
import fur20 from '../assets/images/pdf/fur/20.pdf'



export default () => (
  <Layout>
    <SEO title="Fur" />
      
      <div className="py-5">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={3} className="divContent">
            <Link to="/artikler"> &lt;-- </Link>
            </Col>
            <Col md={5} className="divContent">
            <h1>Klimaforandring på vulkansk kulkraft</h1>
 
<p>
Handler om gigantiske vulkanudbrud i Nordatlanten for 55 millioner år siden. Passer til side 10-11 i skoleavisen.

<br /><br />

<a href = {fur01} target="_blank" rel="noreferrer">
  1 Moleret - et skatkammer
</a>

<br /><br />

<a href = {fur02} target="_blank" rel="noreferrer">
  2 Eks havbund af særlig karakter
</a>

<br /><br />

<a href = {fur03} target="_blank" rel="noreferrer">
  3 Lagene - iltsvind og vulkaner
</a>

<br /><br />

<a href = {fur04} target="_blank" rel="noreferrer">
  4 Den vulkanske stregkode
</a>

<br /><br />

<a href = {fur05} target="_blank" rel="noreferrer">
  5 Opvarmning på vulkansk kulkraft
</a>

<br /><br />

<a href = {fur06} target="_blank" rel="noreferrer">
  6 Fossiler - skattejagt
</a>

<br /><br />

<a href = {fur07} target="_blank" rel="noreferrer">
  7 Monsterfisk
</a>

<br /><br />

<a href = {fur08} target="_blank" rel="noreferrer">
  8 Havkrybdyr - de dyre drenge
</a>

<br /><br />

<a href = {fur09} target="_blank" rel="noreferrer">
  9 Moderne fugle - eksklusiv
</a>

<br /><br />

<a href = {fur10} target="_blank" rel="noreferrer">
  10 Insekter - irriterende, ikke i moler
</a>

<br /><br />

<a href = {fur11} target="_blank" rel="noreferrer">
  11 Moderne planters indtog
</a>

<br /><br />

<a href = {fur12} target="_blank" rel="noreferrer">
  12 Hårde drenge - de hærdede lag
</a>

<br /><br />

<a href = {fur13} target="_blank" rel="noreferrer">
  13 Naturlig kunst - farverne
</a>

<br /><br />

<a href = {fur14} target="_blank" rel="noreferrer">
  14 Moler for milliarder
</a>

<br /><br />

<a href = {fur15} target="_blank" rel="noreferrer">
  15 Istiden som kunstner
</a>

<br /><br />

<a href = {fur16} target="_blank" rel="noreferrer">
  16 Kvik - fiskerester
</a>

<br /><br />

<a href = {fur17} target="_blank" rel="noreferrer">
  17 Kvik - former
</a>

<br /><br />

<a href = {fur18} target="_blank" rel="noreferrer">
  18 Danekræ - loven
</a>

<br /><br />

<a href = {fur19} target="_blank" rel="noreferrer">
  19 Molerlandskabet
</a>

<br /><br />

<a href = {fur20} target="_blank" rel="noreferrer">
  20 Museer, bøger og links
</a>

</p>

            
            </Col>
            <Col md={4} className="divContent">
            <img src={artikler_fur_bg} className="imgSize" alt="Klimaforandring på vulkansk kulkraft" />
            </Col>
          </Row>
      
        </Container>
      </div>
  </Layout>
)
